const { createClient } = require('@wix/sdk/client');
const { site } = require('@wix/site');
const path = require('path');

const WIX_CODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762';
const DASHBOARD_PAGES_QUERY_PARAM = 'inBizMgr';

function getWixClientApiBaseUrl(wixSdk) {
  let sdkBaseUrl = wixSdk?.location?.baseUrl;

  // When we in iframe, we should use the iframe URL which looks like https://<guid>.pub.wix-code.com
  if (wixSdk?.window?.viewMode === 'Preview') {
    sdkBaseUrl = wixSdk?.elementorySupport?.baseUrl;
  }

  if (!sdkBaseUrl) {
    return '';
  }

  // clean the protocol from the URL
  const parsedUrlObject = new URL(sdkBaseUrl);
  sdkBaseUrl = path.join(
    parsedUrlObject.hostname,
    parsedUrlObject.pathname !== '/' ? parsedUrlObject.pathname : '',
  );

  return sdkBaseUrl;
}

const isCurrentSessionDashboardPage = (wixSdk) => {
  const isDashboardPageQueryParam =
    wixSdk?.location?.query?.[DASHBOARD_PAGES_QUERY_PARAM];

  return isDashboardPageQueryParam === 'true';
};

const createSiteHost = (wixSdk) => {
  const clientSiteHost = {
    async invoke({ applicationId, namespace, method, args }) {
      if (wixSdk?.[namespace]) {
        // method might be in the format of 'currentMember.getProfile'
        const methods = method.split('.');
        let currentObject = wixSdk[namespace];

        for (const methodPart of methods) {
          if (currentObject?.[methodPart] !== undefined) {
            currentObject = currentObject[methodPart];
          } else {
            console.error('Wix Site SDK - method not found', {
              applicationId,
              namespace,
              method,
            });
            return undefined;
          }
        }
        if (typeof currentObject === 'function') {
          return currentObject(...args);
        } else {
          // might be undefined if console.error was called above
          return currentObject;
        }
      } else {
        console.error('Wix Site SDK - namespace not found', {
          applicationId,
          namespace,
          method,
        });
      }
    },
  };

  const sdkBaseUrl = getWixClientApiBaseUrl(wixSdk);

  const siteHost = {
    ...site.host({
      applicationId: WIX_CODE_APP_DEF_ID,
      clientSdk: clientSiteHost,
    }),
    apiBaseUrl: sdkBaseUrl,
    essentials: {
      language:
        wixSdk.window?.multilingual?.currentLanguage ?? wixSdk.site?.language,
      locale: wixSdk.window?.locale,
    },
  };

  return {
    host: siteHost,
    hostImplementation: clientSiteHost,
  };
};

const getDashboardHost = (wixSdk) => ({
  host: wixSdk.dashboardHost,
});

const createClientSdk = (wixSdk) => {
  const { host, hostImplementation } = isCurrentSessionDashboardPage(wixSdk)
    ? getDashboardHost(wixSdk)
    : createSiteHost(wixSdk);

  const client = createClient({
    host,
    auth: {
      getAuthHeaders() {
        return {
          headers: {
            Authorization:
              wixSdk.elementorySupport.getRequestOptions().headers
                .Authorization,
          },
        };
      },
    },
  });

  return {
    hostImplementation,
    client,
  };
};

module.exports = {
  createClientSdk,
};
