import { Callback } from './types';

const callbackRegistrar = () => {
  const listeners: Callback[] = [];

  function call(...args: any[]) {
    listeners.forEach((listenerFn) => listenerFn(...args));
  }

  function register(cb: Callback) {
    listeners.push(cb);

    return () => {
      const index = listeners.indexOf(cb);
      if (index >= 0) {
        listeners.splice(index, 1);
      }
    };
  }

  return { register, call };
};

export {
  callbackRegistrar
}
