import valvelet from 'valvelet';
import DBBQ from './dbbq';
import { TelemetryLogSendError } from './logger/errors/telemetryLogSendError';
import traceCreators from './logger/traceCreators';
import { sendTelemetryLog } from './sendTelemetryLog';

interface ThrottledLogSenderOptions {
  fedopsLogger: any;
  appLogger: any;
  requestLimit?: number;
  requestInterval?: number;
  logsPerBatch?: number;
  batchDrainTimeout?: number;
}

const throttledLogSender = ({
  fedopsLogger,
  appLogger,
  requestLimit = 1,
  requestInterval = 1000,
  logsPerBatch = 10,
  batchDrainTimeout = 500,
}: ThrottledLogSenderOptions) => {
  const sendRequest = (logs: any[]) => {
    const traceConfig = traceCreators.sendTelemetryMessages();
    fedopsLogger.interactionStarted(traceConfig.actionName);
    const url = '/wix-code-telemetry-collector/v1/telemetry-messages';
    sendTelemetryLog(url, logs)
      .then(() => {
        fedopsLogger.interactionEnded(traceConfig.actionName);
      })
      // TODO:  break down logs in case of network issue?
      .catch((e: Error) => {
        appLogger.error(new TelemetryLogSendError(e, logs));
      });
  };

  const batchedLogsQueue = DBBQ.create(batchDrainTimeout, logsPerBatch);
  const sendLogThrottled = (log: any) => batchedLogsQueue.add(log);

  const throttledSendRequest = valvelet(
    sendRequest,
    requestLimit,
    requestInterval,
    10000, // queue size
  );
  batchedLogsQueue.onData((logs: any[]) => throttledSendRequest(logs));

  return {
    sendLogThrottled,
  };
};

export { throttledLogSender };
