import { Callback } from './types';

const safeGet = (fn: Callback, defaultValue: any) => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};

export {
  safeGet
}
