const isWebWorker = () => {
  return (
    typeof WorkerGlobalScope !== 'undefined' &&
    self instanceof WorkerGlobalScope // eslint-disable-line no-undef
  );
};

export {
  isWebWorker,
}
