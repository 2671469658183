const enrichUrl = (baseUrl: string, extraParams: URLSearchParams) => {
  const extraQueryParams = Object.keys(extraParams)
    .reduce((acc, key) => [...acc, `${key}=${extraParams[key]}`], [] as string[])
    .join('&');

  const delimeter = baseUrl.includes('?') ? '&' : '?';

  return `${baseUrl}${delimeter}${extraQueryParams}`;
};

export {
  enrichUrl,
};
